import React, { lazy, Suspense, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import logo from "../../../src/assets/images/logo.svg";
import { BriefService } from "services";
import { useSearchParams } from "react-router-dom";
import { Alert } from "@mui/material";
const BriefBrandConsultancyForm = lazy(
  () =>
    import(
      "components/screens/HomePage/BriefBrandConsultancy/BriefBrandConsultancyForm"
    ),
);
const BriefWebsiteDevelopment = lazy(
  () =>
    import(
      "components/screens/HomePage/BriefWebsiteDevelopment/BriefWebsiteDevelopment"
    ),
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      <div className="py-8">
        <Typography>{children}</Typography>
      </div>
    )}
  </div>
);

const Home: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const backgroundStyle = {
    background:
      'linear-gradient(rgba(249, 250, 251, 0.9), rgba(249, 250, 251, 0.9)) center center / cover no-repeat, url("https://client.maido.agency/_next/static/media/bg-login.e7fd9cc6.jpeg")',
  };
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getClient = async () => {
    try {
      const clientId = searchParams.get("clientId");
      if (clientId) {
        const client = await BriefService.getClient(clientId);
        setClient(client);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClient();
  }, []);

  return (
    <div
      className="flex min-h-screen w-full 2xl:relative items-center justify-start flex-col py-8 "
      style={backgroundStyle}
    >
      <div className="2xl:absolute 2xl:left-[-40vw] 2xl:top-8 w-full px-4 flex items-center justify-center mb-4">
        <img src={logo} alt="logo" />
      </div>
      {client && !loading && (
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            label="Brief - Brand Consultancy"
            className="sm:text-lg text-[13px]"
          />
          <Tab
            label="Brief - Website Development"
            className="sm:text-lg text-[13px]"
          />
        </Tabs>
      )}
      {client && !loading && (
        <Suspense
          fallback={
            <Box className="h-screen flex items-center justify-center">
              <CircularProgress />
            </Box>
          }
        >
          <TabPanel value={value} index={0}>
            <BriefBrandConsultancyForm client={client}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BriefWebsiteDevelopment client={client}/>
          </TabPanel>
        </Suspense>
      )}
      {!client && !loading && (
        <Alert className="m-4" variant="filled" severity="error">
          Link không hợp lệ hoặc hết hạn
        </Alert>
      )}
      {loading && (
        <Box className="h-screen flex items-center justify-center">
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default Home;
