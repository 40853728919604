import axios from "axios";
export const customAxios = axios.create({
  // Your base URL goes here
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_BASE_API_KEY,
  },
});

// Request interceptor
customAxios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  },
);

// Response interceptor
customAxios.interceptors.response.use(
  (response) => {
    // Modify the response data here
    return response;
  },
  (error: any) => {
    if (error.response) {
      return Promise.reject(new Error(error.response.data.message));
    }
    return Promise.reject(error);
  },
);
