import { createTheme, responsiveFontSizes } from "@mui/material/styles";
export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#d2a95a",
      },
    },
    typography: {
      fontFamily: "'Montserrat', sans-serif",
    },
  }),
);
