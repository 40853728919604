import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "routes";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </LocalizationProvider>
  );
}

export default App;
