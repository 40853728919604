import { customAxios } from "libs/axiosLib";

class BriefService {
  static async createBriefBrand(payload: any) {
    const res = await customAxios.post("/brief/create-brief-brand", payload);
    const data =res.data;
    if (res.status !== 200) {
      throw data;
    }
  }

  static async createBriefWeb(payload: any) {
    const res = await customAxios.post("/brief/create-brief-web", payload);
    const data =res.data;
    if (res.status !== 200) {
      throw data;
    }
  }

  static async getClient(clientId: string) {
    const res = await customAxios.get(`/brief/get-client/${clientId}`);
    const data =res.data;
    if (res.status !== 200) {
      throw data;
    }
    return data.metadata
  }
}

export default BriefService;
